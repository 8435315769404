import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, Field, change, reset } from "redux-form";
import $, { type } from "jquery";
import ReCAPTCHA from "react-google-recaptcha";

import "./AssinaturaFormLanding1.scss"

import If from "../If/If";
import AssetCcElo from "../../assets/images/credit-card-elo.png"
import AssetCcAmericanExpress from "../../assets/images/credit-card-american-express.png"
import AssetCcMaster from "../../assets/images/credit-card-master.png"
import AssetCcVisa from "../../assets/images/credit-card-visa.png"

import normalizeCreditCardNumber from "../../redux/normalize/normalizeCreditCardNumber";
import normalizeCreditCardValidity from "../../redux/normalize/normalizeCreditCardValidity";
import normalizeCreditCardCVC from "../../redux/normalize/normalizeCreditCardCVC";
import normalizeCreditCardCPF from "../../redux/normalize/normalizeCreditCardCPF";
import { validateData } from "../../common/validateData";
import { getCreditCardFlag } from "../../common/getCreditCardFlag";
import { 
    setButtonCadastrarEnable,
    setButtonConcluirEnable,
    setRecaptchaToken,
    setSubmitLoading,
    setTermosCondicoesModalOpened,
    setPoliticaPrivacidadeModalOpened
} from "../../redux/actions/AssinaturaFormLanding1Actions";

import ReactGA from 'react-ga';

class AssinaturaFormLanding1 extends Component {    
    constructor(props) {
        super(props);

        this.validateFieldset1 = this.validateFieldset1.bind(this);
        this.validateFieldset2 = this.validateFieldset2.bind(this);

        this.validateFieldset1();
    }

    validateFieldset1() {
        const customerEmail = $("[name='customerEmail']").val();
        const termsAndConditionsOfUse = $("[name='termsAndConditionsOfUse']").is(":checked");
        const privacyPolicy = $("[name='privacyPolicy']").is(":checked");

        if (!validateData(customerEmail, "EMAIL")) {
            $("[name='customerEmail']").addClass("input-error");
            this.props.setButtonCadastrarEnable(false);
            return false;
        } else {
            $("[name='customerEmail']").removeClass("input-error");
        }

        if (!termsAndConditionsOfUse) {
            this.props.setButtonCadastrarEnable(false);
            return false;
        }

        if (!privacyPolicy) {
            this.props.setButtonCadastrarEnable(false);
            return false;
        }
        
        this.props.setButtonCadastrarEnable(true);
    }

    async validateFieldset2(recaptchaToken) {
        const creditCardNumber = $("[name='creditCardNumber']").val();
        const creditCardValidity = $("[name='creditCardValidity']").val();
        const creditCardCVC = $("[name='creditCardCVC']").val();
        const creditCardHolder = $("[name='creditCardHolder']").val();
        const creditCardCPF = $("[name='creditCardCPF']").val();
        const acceptCharge  = $("[name='acceptCharge']").is(":checked");
        const recaptchaTokenFromState = this.props.assinaturaFormLanding1Reducer.recaptchaToken;

        if (!validateData(creditCardNumber, "CREDIT_CARD_NUMBER")) {
            $("[name='creditCardNumber']").addClass("input-error");
            $('.error-message-creditCardNumber').css("display", "block")
            this.props.setButtonConcluirEnable(false);
            return false;
        } else {
            $('.error-message-creditCardNumber').css("display", "none")
            $("[name='creditCardNumber']").removeClass("input-error");
        }

        const creditCardFlag = getCreditCardFlag(creditCardNumber);
        if (!creditCardFlag) {
            $("[name='creditCardNumber']").addClass("input-error");
            $('.error-message-creditCardNumber').css("display", "block")

            this.props.setButtonConcluirEnable(false);
            return false;
        } else {
            $("[name='creditCardNumber']").removeClass("input-error");
            $('.error-message-creditCardNumber').css("display", "none")
            this.props.change("creditCardFlag", creditCardFlag);
        }

        if (!validateData(creditCardValidity, "CREDIT_CARD_VALIDITY")) {
            $("[name='creditCardValidity']").addClass("input-error");
            $('.error-message-creditCardValidity').css("display", "block")
            this.props.setButtonConcluirEnable(false);
            return false;
        } else {
            $('.error-message-creditCardValidity').css("display", "none")
            $("[name='creditCardValidity']").removeClass("input-error");
        }

        if (!validateData(creditCardCVC, "CREDIT_CARD_CVC")) {
            $("[name='creditCardCVC']").addClass("input-error");
            $('.error-message-creditCardCVC').css("display", "block")
            this.props.setButtonConcluirEnable(false);
            return false;
        } else {
            $('.error-message-creditCardCVC').css("display", "none")
            $("[name='creditCardCVC']").removeClass("input-error");
        }

        if (!creditCardHolder) {
            $("[name='creditCardHolder']").addClass("input-error");
            $('.error-message-creditCardHolder').css("display", "block")
            this.props.setButtonConcluirEnable(false);
            return false;
        } else {
            $('.error-message-creditCardHolder').css("display", "none")
            $("[name='creditCardHolder']").removeClass("input-error");
        }

        if (!validateData(creditCardCPF, "CREDIT_CARD_CPF")) {
            $("[name='creditCardCPF']").addClass("input-error");
            $('.error-message-creditCardCPF').css("display", "block")
            this.props.setButtonConcluirEnable(false);
            return false;
        } else {
            $('.error-message-creditCardCPF').css("display", "none")
            $("[name='creditCardCPF']").removeClass("input-error");
        }

        if (!acceptCharge) {
            this.props.setButtonConcluirEnable(false);
            return false;
        }

        if (!recaptchaTokenFromState) {
            if (recaptchaToken && typeof(recaptchaToken) === "string") {
                await this.props.setRecaptchaToken(recaptchaToken);
                this.props.change("recaptchaToken", recaptchaTokenFromState);
            } else {
                this.props.setButtonConcluirEnable(false);
                return false;
            }
        } else {
            this.props.change("recaptchaToken", recaptchaTokenFromState);
        }

        this.props.setButtonConcluirEnable(true);
    }

    goToNextStep() {
        $("#assinatura-form #email-info").fadeOut();
        $("#assinatura-form #credit-card-info").fadeIn();

        fetch("variables.json", { 
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        .then(response => {
            if (response.status === 200) return response.json();
        })
        .then(data => {
            const customerEmail = $("[name='customerEmail']").val();
            const urlParams = new URLSearchParams(window.location.search);

            ReactGA.event({
                category: 'CR-LP',
                action: 'InformarEmail',
                label: 'Usuário informou email e avançou para pagamento'
            });

            const body = {
                "email": customerEmail,
                "plan_id": data.plan_id,
                "clickid": urlParams.get('clickid'),
            }

            const headers = {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }

            fetch('https://ads.clubederevistas.com/api/email/index.php', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)
            })
        })
    }

    returnToPreviousStep() {
        $("#assinatura-form #email-info").fadeIn();
        $("#assinatura-form #credit-card-info").fadeOut();
    }

    render() {
        const { handleSubmit } = this.props;

        return (
            <form 
                id="assinatura-form"
                role="form"
            >   
                <fieldset id="email-info">
                    <div className="row m-b-25">
                        <div className="col-12 d-flex">
                            <h4 className="assinatura-l1-form-title p-t-5">Para começar, informe seu e-mail</h4>
                        </div>
                    </div>

                    <div className="row m-b-25">
                        <div className="col-12">
                            <label htmlFor="customerEmail">Você receberá uma senha de acesso ao finalizar sua assinatura.</label>
                            <Field 
                                component="input"
                                name="customerEmail"
                                type="text"
                                placeholder="seunome@provedor.com"
                                onChange={ this.validateFieldset1 }
                            />
                            <span class="error-message error-message-customerEmail">Insira um email válido</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="checkbox-label">
                                <Field 
                                    component="input"
                                    name="termsAndConditionsOfUse"
                                    type="checkbox"
                                    onClick={ this.validateFieldset1 }
                                    className="d-none"
                                    checked
                                />
                                <label htmlFor="acceptCharge" className="d-none">
                                    Li e aceito os 
                                    <span 
                                        className="open-modal-text" 
                                        onClick={ () => this.props.setTermosCondicoesModalOpened(true) }
                                    > Termos e Condições de Uso</span>.
                                </label>
                                {/* <label htmlFor="acceptCharge">
                                    Ao avançar, você concorda com a cobrança mensal de R$ 4,99 realizada neste cartão a partir de hoje. 
                                    Rever <a href="#" onClick={ () => this.props.setTermosCondicoesModalOpened(true) }> termos </a> e 
                                    <a href="#" onClick={ () => this.props.setPoliticaPrivacidadeModalOpened(true) }> condições</a>.
                                </label> */}
                            </div>
                        </div>
                    </div>

                    <div className="row m-b-25">
                        <div className="col-12">
                            <div className="checkbox-label">
                                <Field 
                                    component="input"
                                    name="privacyPolicy"
                                    type="checkbox"
                                    onClick={ this.validateFieldset1 }
                                    className="d-none"
                                    checked
                                />
                                <label htmlFor="acceptCharge" className="d-none">
                                    Li e aceito a
                                    <span 
                                        className="open-modal-text" 
                                        onClick={ () => this.props.setPoliticaPrivacidadeModalOpened(true) }
                                    > Política de Privacidade</span>.
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 text-center">
                            <button
                                className={`the-button bg-pink btn-md text-white${!this.props.assinaturaFormLanding1Reducer.enableButtonCadastrar ? " btn-disabled" : ""}`}
                                onClick={this.goToNextStep}
                                type="button"
                                disabled={ !this.props.assinaturaFormLanding1Reducer.enableButtonCadastrar }
                            >
                                QUERO LER!
                            </button>
                        </div>
                    </div>
                </fieldset>
                <fieldset id="credit-card-info">
                    <div className="row m-b-25">
                        <div className="col-12">
                            <div className="assinatura-l1-pagamento-title">
                                <h4 className="assinatura-l1-form-title p-t-5">1º mês grátis, depois só R$ 4,99/mês!</h4>
                                <div className="assinatura-l1-cards m-l-auto">
                                    <img src={ AssetCcMaster } className="w-60" />
                                    <img src={ AssetCcVisa } className="w-60" />
                                    <img src={ AssetCcElo } className="w-60" />
                                    <img src={ AssetCcAmericanExpress } className="w-60" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row m-b-25">
                        <div className="col-12">
                            <label htmlFor="creditCardNumber">Número do cartão de crédito</label>
                            <Field 
                                component="input"
                                name="creditCardNumber"
                                type="text"
                                placeholder="0000.0000.0000.0000"
                                normalize={normalizeCreditCardNumber}
                                onChange={ this.validateFieldset2 }
                            />
                            <span class="error-message error-message-creditCardNumber">Insira um número de cartão de crédito válido.</span>
                        </div>
                    </div>
                    <div className="row m-b-25">
                        <div className="col-6">
                            <label htmlFor="creditCardValidity">Validade</label>
                            <Field 
                                component="input"
                                name="creditCardValidity"
                                type="text"
                                placeholder="00/0000"
                                normalize={normalizeCreditCardValidity}
                                onChange={ this.validateFieldset2 }
                            />
                            <span class="error-message error-message-creditCardValidity">Insira uma data de validade correta.</span>
                        </div>
                        <div className="col-6">
                            <label htmlFor="creditCardCVC">CVC</label>
                            <Field 
                                component="input"
                                name="creditCardCVC"
                                type="text"
                                placeholder="000"
                                normalize={normalizeCreditCardCVC}
                                onChange={ this.validateFieldset2 }
                            />
                            <span class="error-message error-message-creditCardCVC">Insira o código de 3 dígitos exibido no verso do cartão (Amex: 4 dígitos exibidos na frente)</span>
                        </div>
                    </div>
                    <div className="row m-b-25">
                        <div className="col-12">
                        <label htmlFor="creditCardHolder">Nome do titular (igual ao cartão)</label>
                            <Field
                                component="input"
                                name="creditCardHolder"
                                type="text"
                                placeholder="Digite o nome igual ao do cartão"
                                onChange={ this.validateFieldset2 }
                            />
                            <span class="error-message error-message-creditCardHolder">Insira o nome do titular como está no cartão</span>
                        </div>
                    </div>
                    <div className="row m-b-25">
                        <div className="col-12">
                            <label htmlFor="creditCardCPF">CPF do titular do cartão</label>
                            <Field
                                component="input"
                                name="creditCardCPF"
                                type="text"
                                placeholder="000.000.000-00"
                                normalize={normalizeCreditCardCPF}
                                onChange={ this.validateFieldset2 }
                            />
                            <span class="error-message error-message-creditCardCPF">Insira um número de CPF válido.</span>
                        </div>
                    </div>
                    <div className="row m-b-25">
                        <div className="col-12">
                            <div className="checkbox-label">
                                <Field 
                                    component="input"
                                    name="acceptCharge"
                                    type="checkbox"
                                    onClick={ this.validateFieldset2 }
                                    checked
                                    className="d-none"
                                />
                                <label htmlFor="acceptCharge">
                                    Ao avançar, você concorda com a cobrança mensal de R$ 4,99 realizada neste cartão a partir de hoje. 
                                    Rever <a href="#" onClick={ () => this.props.setTermosCondicoesModalOpened(true) }> termos </a> e 
                                    <a href="#" onClick={ () => this.props.setPoliticaPrivacidadeModalOpened(true) }> condições</a>.
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row m-b-25">
                        <div className="col-12 d-flex justify-content-center">
                            <div className="assiantura-recaptcha">
                                <ReCAPTCHA
                                    sitekey="6Ldz0M4aAAAAAC2WWE44sG2XOJnyCsDp_V3KeELL"
                                    onChange={ this.validateFieldset2 }
                                />
                            </div>
                        </div>
                    </div>

                    <div className="recaptcha-space-fake"></div>

                    <div className="row">
                        <div className="col-12 d-flex justify-content-center text-pink">
                            <a onClick={ this.returnToPreviousStep }>Voltar à etapa anterior</a>
                        </div>
                    </div>
                    <div className="row m-t-50">
                        <div className="col-12 text-center">
                            <If test={!this.props.assinaturaFormLanding1Reducer.submitLoading}>  
                                <button
                                    className={`the-button bg-pink btn-md text-white${!this.props.assinaturaFormLanding1Reducer.enableButtonConcluir ? " btn-disabled" : ""}`}
                                    onClick={handleSubmit}
                                    type="submit"
                                    disabled={ !this.props.assinaturaFormLanding1Reducer.enableButtonConcluir }
                                >
                                    ACESSAR AGORA!
                                </button>
                            </If>

                            <If test={this.props.assinaturaFormLanding1Reducer.submitLoading}>
                                <button
                                    className={`the-button bg-pink btn-md text-white btn-disabled`}
                                    onClick={handleSubmit}
                                    type="submit"
                                    disabled={ true }
                                >
                                    Salvando...
                                </button>
                            </If>
                        </div>
                    </div>
                    <If test={this.props.assinaturaFormLanding1Reducer.formError}>
                        <div className="row m-t-20">
                            <div className="col-12">
                                <div className="form-alert">
                                    <p>{this.props.assinaturaFormLanding1Reducer.formErrorMessage}</p>
                                </div>
                            </div>
                        </div>
                    </If>
                </fieldset>
            </form>
        )
    }
}

AssinaturaFormLanding1 = reduxForm({
    form: "assinaturaLanding1Form",
    destroyOnUnmount: true,
    onSubmitSuccess: (result, dispatch) => { dispatch(reset("assinaturaLanding1Form")) },
    onSubmitFail: () => { return false }
})(AssinaturaFormLanding1);
const mapStateToProps = state => ({ assinaturaFormLanding1Reducer: state.assinaturaFormLanding1Reducer });
const mapDispatchToProps = dispatch => bindActionCreators({
    setButtonCadastrarEnable,
    setButtonConcluirEnable,
    change,
    setRecaptchaToken,
    setSubmitLoading,
    setTermosCondicoesModalOpened,
    setPoliticaPrivacidadeModalOpened
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AssinaturaFormLanding1);