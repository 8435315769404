import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactFullpage from '@fullpage/react-fullpage'; 

import Menu from "../../components/Menu/Menu"
import FullPage from "../../components/FullPage/FullPage"

import { setMenuColor } from '../../redux/actions/Menu'

import ReactGA from 'react-ga';

import "./LandingPage1.scss"
export class LandingPage1 extends Component {
    componentDidMount() {
        ReactGA.initialize('G-R9SRKTCPZM');
        // ReactGA.initialize('G-XBWX4RZ0Q2');
    }

    render() {
        return (
           <main id="main-fullscroll" className="main-app">
               <Menu logoLing={'#inicio'} />
               <FullPage />
           </main> 
        );
    }
}

const mapStateToProps = state => ({ MenuReducer: state.MenuReducer });

const mapDispatchToProps = dispatch => bindActionCreators({ 
    setMenuColor,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage1)