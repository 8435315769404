import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import App from "./containers/App/App";
import { LandingPage1 } from "./containers/LandingPage1/LandingPage1";
// import LandingPage2 from "./containers/LandingPage2/LandingPage2";
// import Home from "./containers/Home/Home";

const Routes = props => (
    <BrowserRouter>
        <Switch>
            <App>
                {/* <Route path="/landing-1" component={LandingPage1} />
                <Route path="/landing-2" component={LandingPage2} />
                <Route path="/home" component={Home} /> */}

                <Route path="/" component={LandingPage1} />
            </App>
        </Switch>
    </BrowserRouter>
);

export default Routes;