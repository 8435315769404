import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import headerLogoWhite from '../../assets/images/landing-page-1/logo-header-white.png'
import headerLogoBlack from '../../assets/images/landing-page-1/logo-header-black.png'

import { setMenuColor } from '../../redux/actions/Menu'

import './Menu.scss'

export class Menu extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return(
            <nav className={`the-menu the-menu-${this.props.MenuReducer.menuColor}`}>
                <div className="the-menu-container container">
                    <div className="the-menu-logo cursor-pointer w-90" onClick={ () => window.location.href = "#banner-l1" }>
                        <img src={ this.props.MenuReducer.menuColor === "transparent" ? headerLogoWhite : headerLogoBlack } alt="Header logo"/>
                    </div>

                    <ul>
                        <li className="d-none d-lg-inline ">
                            <a href="#banner-l1">
                                Início
                            </a>
                        </li>
                        <div className="ellipse d-none d-lg-inline"></div>
                        <li className="d-none d-lg-inline ">
                            <a href="#club-advantages-landing-1">
                                Vantagens do Clube
                            </a>
                        </li>
                        <div className="ellipse d-none d-lg-inline"></div>
                        <li className="d-none d-lg-inline">
                            <a href="#como-funciona-l1">
                                Como funciona?
                            </a>
                        </li>
                        <div className="ellipse d-none d-lg-inline"></div>
                        <li className="d-none d-lg-inline ">
                            <a href="#lancamentos-l1">
                                Últimas edições
                            </a>
                        </li>
                        <div className="ellipse d-none d-lg-inline"></div>
                        <li>
                            <a className="the-menu-link-facaparte the-button-outline"
                            href="#assinatura-l1">
                                Faça parte do Clube
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        )
    }
}

const mapStateToProps = state => ({ MenuReducer: state.MenuReducer })

const mapDispatchToProps = dispatch => bindActionCreators({ 
    setMenuColor,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Menu)