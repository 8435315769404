export const validateData = (value, type) => {
    if (!value || !type) return false;

    switch(type) {
        case "CREDIT_CARD_NUMBER":
            const cardNumberOnlyNumbers = value.replaceAll(".", "");
            const cardNumberOnlyNumbersLength = cardNumberOnlyNumbers.length;

            if (cardNumberOnlyNumbersLength !== 16 && cardNumberOnlyNumbersLength !== 15) return false;
            else return true;

        case "CREDIT_CARD_VALIDITY":
            const cardValidityOnlyNumbers = value.replaceAll("/", "");
            const cardValidityOnlyNumbersLength = cardValidityOnlyNumbers.length;
            const cardValidityMonth = parseInt(value.split("/")[0]);
            const cardValidityYear = parseInt(value.split("/")[1]);
            const currentYear = new Date().getFullYear();
            const currentYearTwoCharacters = new Date().getFullYear().toString().substr(-2)

            const cardValidityYearLength = cardValidityYear.toString().length

            if (cardValidityOnlyNumbersLength != 4 && cardValidityOnlyNumbersLength != 6) {
                return false;
            } else if (cardValidityMonth <= 0 || cardValidityMonth > 12 || cardValidityYear <= 0) {
                return false;
            } else if (cardValidityYearLength === 4 && cardValidityYear < currentYear) {
                return false;
            } else if (cardValidityYearLength === 2 && cardValidityYear < currentYearTwoCharacters) {
                return false;
            } else {
                return true;
            }
        
        case "CREDIT_CARD_CVC":
            const cardCVCLength = value.length;

            if (cardCVCLength !== 3 && cardCVCLength !== 4) return false;
            else return true;
        
        case "CREDIT_CARD_CPF":
            const cardCPFOnlyNumbers = value.replaceAll(".", "").replaceAll("-", "");
            const cardCPFOnlyNumbersLength = cardCPFOnlyNumbers.length;

            if (cardCPFOnlyNumbersLength != 11) return false;
            else if (!isCPFValid(cardCPFOnlyNumbers)) return false;
            else return true;
        
        case "EMAIL":
            if (!isEmailValid(value)) return false;
            else return true;

        default: return;
    }
}

const isCPFValid = (cpf) => {
    var numeros, digitos, soma, i, resultado, digitos_iguais;
    digitos_iguais = 1;
    
    if (cpf.length < 11)
        return false;
    for (i = 0; i < cpf.length - 1; i++)
        if (cpf.charAt(i) != cpf.charAt(i + 1)) {
            digitos_iguais = 0;
            break;
        }
    if (!digitos_iguais) {
        numeros = cpf.substring(0,9);
        digitos = cpf.substring(9);
        soma = 0;
        
        for (i = 10; i > 1; i--)
            soma += numeros.charAt(10 - i) * i;
        
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        
        if (resultado != digitos.charAt(0))
            return false;
        
        numeros = cpf.substring(0,10);
        soma = 0;
        
        for (i = 11; i > 1; i--)
            soma += numeros.charAt(11 - i) * i;
        
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        
        if (resultado != digitos.charAt(1))
                return false;
        return true;
    } else {
        return false;
    }
}

const isEmailValid = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}